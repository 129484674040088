<template>
  <div class="crm f-flex">
    <div class="crm-name">退水資料</div>
  </div>

  <div class="s-box f-flex" style="background:rgba(255,144,0,0.05); padding:10px 10px; border-radius: 10px; width: 480px;">
    <div class="s-t">賬戶名稱：</div>
    <div class="s-t f-plr10 f-f-blue">
      {{ store.state.player.profile.nickName }}
    </div>

    <div class="s-t">信用額度：</div>
    <div class="s-t f-plr10 f-f-blue">
      {{ store.state.player.profile.totalDeposit }}
    </div>

    <div class="s-t">幣别：</div>
    <div class="s-t f-plr10 f-f-blue">人民幣</div>
  </div>
    <div class="s-box f-flex" style="background:rgba(255,144,0,0.05); padding:10px 10px; border-radius: 10px; width: 480px;">
    <div class="s-t" style="color:red;"> 每日15:15-16:30 以及18:45-20:00为亚赔极速官方定期维护时间，期间不提供赛事行情及交易。其它时间均可下注.(亚赔极速官方不定期会停机维护，期间不可交易，时间不定)</div>
  </div>
  <div v-if="loading" class="loading"></div>
  <div class="table-con">
    <div class="m-table f-flex" style="border: 0">
      <div class="g-w50 tr" v-for="(item, index) in [0, 1]" :key="index" >
        <div class="f-flex" >
          <div class="th g-w25" style="background:rgba(0,0,0,0.02) !important; color:#000; font-weight: bold;">項目</div>
          <div class="th g-w15" style="background:rgba(0,0,0,0.02) !important; color:#000; font-weight: bold;">單註最低</div>
          <div class="th g-w15" style="background:rgba(0,0,0,0.02) !important; color:#000; font-weight: bold;">單註最高</div>
          <div class="th g-w15" style="background:rgba(0,0,0,0.02) !important; color:#000; font-weight: bold;">單项最高</div>
          <div class="th g-w30 cut" style="background:rgba(0,0,0,0.02) !important; color:#000; font-weight: bold;">退水</div>
        </div>
      </div>
    </div>

    <div
      class="m-table f-flex"
      style="flex-wrap: wrap; border: 0"
      v-for="(item, index) in [0, 1]"
      :key="index"
    >
      <div
        class="g-w50 tr"
        v-for="(items, index) in records.data[item]"
        :key="index"
      >
        <div class="f-flex">
          <div class="td g-w25">{{ items.betTypeName }}</div>
          <div class="td g-w15">{{ items.minMoney }}</div>
          <div class="td g-w15">{{ items.maxMoney }}</div>
          <div class="td g-w15">{{ items.subMaxMoney }}</div>
          <div class="td g-w30 cut">{{ filterFeeAbc(items) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, reactive, nextTick, computed, watch } from "vue";
import { useStore } from "vuex";
import chunk from "lodash.chunk";
export default {
  name: "LhcBackwater",
  components: {},
  setup() {
    const store = useStore();
    const loading = ref(false);
    let records = reactive({
      data: [],
    });
    const currentGame = computed(() => {
      return store.state.app.currentGame;
    });
    watch(
      () => [currentGame.value.code],
      () => {
        userAccountGameFilter();
      }
    );
    onMounted(() => {
      nextTick(() => {
        userAccountGameFilter();
      });
    });
    //退水資料
    const userAccountGameFilter = () => {
      loading.value = true;
      store
        .dispatch("player/userAccountGameFilterAction", {
          gameCode: currentGame.value.code,
        })
        .then((res) => {
          loading.value = false;
          if (res && res.code == 0) {
            records.data = chunk(res.res, 50);
            //  console.log("records.data---->", records.data);
          } else {
            records.data = [];
          }
        })
        .catch(() => {
          loading.value = false;
        });
    };
    const filterFeeAbc = (record) => {
      let feeAry = [];
      if (record.hasOwnProperty("fee")) {
        feeAry.push("A:" + record.fee);
      }
      if (record.hasOwnProperty("feeB")) {
        feeAry.push("B:" + record.feeB);
      }
      if (record.hasOwnProperty("feeC")) {
        feeAry.push("C:" + record.feeC);
      }
      return feeAry.join(" ");
    };
    return { store, records, loading, userAccountGameFilter, filterFeeAbc };
  },
};
</script>
